.kl-section-scheduler {

  &__timepicker .ant-picker-footer {
    display: none;
  }

  &__day-entry-list {
    display: flex;
    flex-direction: column;
    margin-top: @margin-md;
    gap: @margin-md;
  }

  &__day-entry-list-item {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    gap: @margin-md;
  }
}
