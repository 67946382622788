.signature-pad {
  display: flex;
  flex-direction: column;
  height: 200px;
  border: 2px solid gray;

  &__canvas {
    width: 100%;
    height: 100%;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
