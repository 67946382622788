.material-list {
  display: flex;
  flex-direction: column;
  gap: @padding-md;
}

.material-item {
  display: flex;
  gap: @padding-lg;
  height: 150px;

  & .ant-image-img {
    max-height: 100%;
    max-width: 100%;
  }

  &__info {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 400px;
  }

  &__sharing {
    display: flex;
  }

  &__meta {
    display: flex;
    //gap: 4px;
  }
}

.sharing-access-list {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    padding: @padding-xs;
    margin-left: -@padding-xs;
    margin-right: -@padding-xs;

    &:not(:last-child) {
      border-bottom: 1px solid @table-border-color;
    }

    &:hover {
      background-color: @table-row-hover-bg;
    }
  }

  &__shared-to {
    flex: 1;
  }
}

.material-sharing-modal {
  &__select-control {
    display: flex;
    gap: @padding-sm;

    & > div {
      width: 100%;
    }
  }
}
