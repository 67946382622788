.teacher-drawer {
  &__sections {
    display: flex;
    flex-direction: column;
    gap: @padding-md;
  }
}

.kl-teacher-table {
  &__name {
    min-width: 180px;
  }

  &__sections {
    & > .ant-space {
      flex-wrap: wrap;
    }
  }
}
