@import '../../../node_modules/antd/lib/style/color/colors.less';

@primary-1: #faf5ff;
@primary-2: #f3e8ff;
@primary-3: #e9d5ff;
@primary-4: #d8b4fe;
@primary-5: #c084fc;
@primary-6: #a855f7;
@primary-7: #9333ea;
@primary-8: #7e22ce;
@primary-9: #6b21a8;
@primary-10: #581c87;

@primary-color: @primary-9;

@info-color: @blue-6;

// Button
.ant-btn.ant-btn-text:hover {
  color: @primary-color;
}
