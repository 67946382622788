.kl-table {
  &__link {
    color: @text-color;
  }
}

.kl-table.kl-table--drawer {
  tbody > tr {
    cursor: pointer;
  }
}
